.codeSetList table>tbody>tr>td:nth-child(3) {
    text-align: right;
}

.codeSetList table>tbody>tr>td:nth-child(2) {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    max-width: 1px;
}
