a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
}

.box-shadow {
    box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}


.nav-company-logo {
    display: none;
    margin-bottom: 5px;
}

@media (min-width: 1000px) {
    .nav-company-logo {
        display: inherit;
    }
}
