.highlight{
    stroke: transparent;
}

.connector:hover>.highlight {
    stroke: #0dcaf0;
    marker-start: url(#arrowHighlight);
}

.connector {
    stroke: black;
    fill: none;
}
