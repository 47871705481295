g>.selected {
    .edit-button {
        visibility: visible;
    }
}

.edit-button {
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    visibility: hidden;
}

