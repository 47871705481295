.summary {
    flex-wrap: wrap-reverse;
}

@container (min-width: 700px) {
    .summary {
        flex-wrap: nowrap;
    }
}

@container (max-width: 700px) {
    .summary {
        flex-wrap: wrap-reverse;
    }
}
