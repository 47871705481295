$handles: 's', 'w', 'e', 'n', 'sw', 'nw', 'se', 'ne';

@each $handle in $handles {
    .handle-#{$handle} {
        cursor: #{$handle}-resize;
        visibility: hidden;
        fill: #0dcaf080;
        stroke: white;
    }

    :hover>.handle-#{$handle} {
        visibility: visible;
    }

    .selected>.handle-#{$handle} {
        visibility: visible;
    }
}
