.dragging {
    filter: url(#dropshadow);
    cursor: grabbing;
}

.draggable:not(.dragging) {
    cursor: grab;
}

.undraggable {
    cursor: inherit;
}
