@import "../../../../../node_modules/bootstrap/scss/functions";
@import "../../../../../node_modules/bootstrap/scss/variables";
@import "../../../../../node_modules/bootstrap/scss/mixins";

.tab {
    cursor: pointer;
    user-select: none;

    a.tab-link {
        border-color: $nav-tabs-link-hover-border-color;
        background-color: $gray-100;

        &:hover {
            background-color: $gray-200;
            border-color: $gray-400 $gray-300 $nav-tabs-border-color;
        }
    }

    .tab-title {
        max-width: 10em;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

.close-button {
    box-sizing: content-box;
    padding: 0 $btn-close-padding-x;
    color: $btn-close-color;
    border: 0;
    @include border-radius();
    opacity: $btn-close-opacity;

    &:focus,
    &:hover {
        background-color: $gray-500;
    }

    &:hover {
        color: $btn-close-color;
        text-decoration: none;
        opacity: $btn-close-hover-opacity;
    }

    &:focus {
        outline: 0;
        box-shadow: $btn-close-focus-shadow;
        opacity: $btn-close-focus-opacity;
    }

    &:disabled,
    &.disabled {
        pointer-events: none;
        user-select: none;
        opacity: $btn-close-disabled-opacity;
    }
}
