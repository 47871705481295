.diagram-table-body {
    font-size: 10px;
    line-height: 15px;
}

.diagram-table-body>tbody>tr>td:last-child {
    padding-right: 2px;
}

.diagram-table-body>tbody>tr {
    height: 16px;
}

.diagram-table-body>tbody>tr>td {
    padding-left: 2px;
    background-color: transparent;
}

.flags td:not(:first-child) {
    text-align: left;
    padding-left: 0.3rem;
}

.flags td:first-child {
    text-align: right;
}

.index-detail .title {
    margin-top: 0.3rem;
    margin-bottom: 0;
    text-align: left;
}

.index-detail ul {
    list-style-type: none;
    padding-top: 0;
    margin-top: 0;
}

.index-detail>ul>li {
    text-align: left;
}

.constraint-detail .title {
    margin-top: 0.3rem;
    margin-bottom: 0;
    text-align: left;
}

.constraint-detail .table {
    margin-top: 0;
    margin-bottom: 0;
    text-align: left;
}
.constraint-detail ul {
    list-style-type: none;
    padding-top: 0;
    margin-top: 0;
}

.constraint-detail>ul>li {
    text-align: left;
}
