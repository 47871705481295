.page-loading-container {
    background-color: #0087c9;
    color: white;
    fill: white;

    width: 100vw;
    height: 100vh;

    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
    justify-content: center;
}

.page-loading-spinner {
    animation-name: stretch;
    animation-duration: 2.0s;
    animation-timing-function: ease-out;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-play-state: running;

    height: 10em;
    width: 10em;
}

@keyframes stretch {
    0% {
        transform: scale(.95);
    }

    100% {
        transform: scale(1.05);
    }
}
